@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";
@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

$cao-primary: #9ad6f1;

html {
    scroll-behavior: smooth;
}


body,
.btn,
.u-primaryFont,
.u-heading,
h1, h2, h3, h4, h5, h6 {
    font-family: 'Inter', sans-serif;
    text-transform: none;
}

body {
    //max-width: 1200px;
    //margin-left: auto;
    //margin-right: auto;
    //background-color: $gray-200;
    //box-shadow: 0 0 2rem rgba(0,0,0,.5);
}

h2, .h2 {
    @include media-breakpoint-up(lg) {
        font-size: 3rem;
    }
}

.container-fluid {
    padding: 0;

    .row {
        margin-left: 0;
        margin-right: 0;

        [class*='col-'],
        .col {
            padding: 0;
        }
    }
}

// Override above
.NewsletterSignup {
    padding: 1rem;
}

//Checkboxar
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $cao-dark-blue;
}

.btn {
    text-transform: none;

    &-primary {
        color: $cao-dark-blue;
        background-color: transparent;
        border-color: $cao-dark-blue;

        &:hover {
            background-color: $cao-dark-blue;
            border-color: $cao-dark-blue;
        }
    }

    &-dark {
        background-color: $cao-dark-blue;
        border-color: $cao-dark-blue;

        &:hover {
            background-color: $black;
            border-color: $black;
        }
    }

    &-outline-light {
        &:hover {
            color: $white;
            border-color: $cao-primary;
            background-color: $cao-primary;
        }
    }

    &-outline-primary {
        border-color: $cao-medium-blue;
        color: $cao-medium-blue;

        &:hover {
            color: $white;
            background-color: $cao-medium-blue;
            border-color: $cao-medium-blue;
        }
    }
}

// Footer contact form
.footer-contactform {
    .form-control {
        border-radius: 15rem;
    }

    .btn-primary {
        color: $white;
        border-color: $cao-primary;
        background-color: $cao-primary;
    }

    h2 {
        text-align: center;
    }
}


// Make all buttons bigger
.btn,
body .NewsletterSignup-input .btn {
    font-size: 1.3rem !important;
    font-weight: 700 !important;
}