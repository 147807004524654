@import "../../imports.scss";

$footer-bg: #286da9;

$cao-light-blue: #c4dfea;
$cao-medium-blue: #386376;
$cao-dark-blue: #1c1e33;
$cao-orange: #df6a23;
$cao-orange-hover: #DB5300 ;

$cao-green: #007f6a;

$custom-checkbox-indicator-icon-checked: $custom-checkbox-indicator-icon-checked-white;
